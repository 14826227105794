import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../app/auth';

function Login() {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const errorMessage = useSelector((state) => state.auth.errorMessage);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(credentials));
  };

  return (
    <div className='h-screen grid justify-items-center items-center'>
      <div>
        <h2 className='text-center font-bold text-xl'>Вход через логин и пароль</h2>
        <p className='text-center text-sm text-gray-300 my-2'>Логин и пароль</p>
        {errorMessage && <div className="mb-4 text-red-500 text-center">{errorMessage}</div>}
        <form action="" onSubmit={handleSubmit}>
          <input
            className='w-full border-2 border-gray-300 p-2 rounded-lg mb-3'
            type="text"
            placeholder='Логин'
            name='username'
            value={credentials.username}
            onChange={handleChange}
            required
          />
          <input
            type="password"
            placeholder='Пароль'
            className='w-full border-2 border-gray-300 p-2 rounded-lg mb-3'
            name='password'
            value={credentials.password}
            onChange={handleChange}
            required
          />
          <button
            className={`bg-blue-500 text-white text-sm font-medium w-full p-2 mt-7 rounded-md hover:bg-blue-600 transition-colors ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isLoading}
            type='submit'
          >
            {isLoading ? 'Вход...' : 'Войти'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
