import { Routes, Route, Navigate } from 'react-router-dom'
import { lazy } from 'react'
import Login from './pages/login';
import './index.css'
const Layout = lazy(() => import('./containers/Layout'));

function App() {

  const otdel = localStorage.getItem('otdel');

  return (
    <>
      <Routes>
        <Route path='/*' element={<Layout />} />
        <Route path="/" element={
          otdel === '0' ? <Navigate to="/home" /> : otdel === '1'
            ? <Navigate to="/otdel_one/dashboard" /> : otdel === '2'
              ? <Navigate to="/otdel_two/dashboard" /> : otdel === '3'
                ? <Navigate to="/otdel_three/dashboard" /> : <Login />}
        />
      </Routes>
    </>
  );
}

export default App;